.custom-group{

}

.custom-group .form-group{
    position: relative;
}

.custom-group .form-group .custom-label{
    position: absolute;
    background-color: #fff;
    left: 10px;
    top: 18px;
    font-size: 16px;
    padding: 0 7px;
    box-sizing: border-box;
    pointer-events: none;
    transition: all 0.2s;
}

.custom-group .form-group .form-control[preenchido="true"] ~ .custom-label,
.custom-group .form-group .form-control:active ~ .custom-label,
.custom-group .form-group .form-control:focus ~ .custom-label{
    font-size: 11px;
    /* font-size: 13px; */
    top: -10px;
    transition: all 0.2s;
}

.custom-group .form-group .form-control:active ~ .custom-label,
.custom-group .form-group .form-control:focus ~ .custom-label{
    color: var(--color-primary);
}

.custom-group .form-group .form-control{
    border: solid 2px rgb(243, 243, 243);
    padding: 12.5px 10px;
    font-size: 14px;
    min-height: 50px !important;
    /* font-size: 16px; */
    /* padding: 16.5px 14px; */
    /* min-height: 61px !important; */
    color: transparent;
}

.custom-group .form-group select.form-control{
    height: auto !important;
}


.custom-group.select-filter .form-group .form-control[type="text"]{
    min-height: 20px !important;
}

.custom-group.select-filter option{
    padding: 5px 0;
    margin-bottom: 5px;
}

.custom-group.select-filter .form-group select.form-control{
    height: auto;
}
.custom-group .form-group .form-control.sm{
    padding: 6.5px 10px;
    height: auto;
    min-height: 41px !important;
}

.custom-group .form-group .form-control:focus,
.custom-group .form-group .form-control:active{
    border-color: var(--color-primary);
    box-shadow: none;
    color: inherit;
}
.custom-group .form-group .form-control[preenchido="true"]{
    color: inherit;
}

.custom-group .form-group .form-control:disabled{
    
    background-color: #fff !important;
    pointer-events: none !important;
}

::placeholder{
    color: #d8d5d5 !important;
}

.custom-group .form-group .form-control:disabled  ~ .custom-label{
}