.dropzone.single{
    position: relative;
    border-radius: 0.25rem;
    overflow: hidden;
}

.dropzone.single .dropzone-container{
    position: relative;
    overflow: hidden;
    height: 250px;
    width: 250px;
    max-width: 250px;
    border: solid 1px rgba(0,0,0,0.1);
    border-radius: 5px;
}
@media (max-width: 576px){
    .dropzone.single .dropzone-container{
        max-width: 100%;
        width: 100%;
    }
}

.dropzone.single .dropzone-container .icon-file{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 65px;
    line-height: 0;
    text-align: center;
}

.dropzone.single .dropzone-container .icon-file  small{
    font-size: 15px;
    margin-bottom: 5px;
}

.dropzone.single .dropzone-container .icon-file i{
    display: block;
}

.dropzone.single .btn-file-upload{
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -200%);
    z-index: 0;
    transition: all 0.5s;
}

.dropzone.single .dropzone-container:hover .btn-file-upload{
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.5s;
}

.dropzone.single .dropzone_input_file {
    display: none;
}

.dropzone.single .container-itens{
    position: relative;
}

.dropzone.single .bg-file-edit::before{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transform: translateY(-110%);
    background: rgba(0,0,0,0.8);
    z-index: 1;
    transition: all 0.5s;
}

.dropzone.single .container-itens:hover .bg-file-edit::before{
    transform: none;
    transition: all 0.5s;
}

.dropzone.single .container-itens .img-container{
    position: relative;
}

.dropzone.single .container-itens .btn-file{
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-100%);
    z-index: 1;
    color: #fff;
    transition: all 0.5s;
}

.dropzone.single .container-itens:hover .btn-file{
    transform: none;
    transition: all 0.5s;
}

.dropzone.single .container-itens .btn-edit{
    right: 50px;
}

.dropzone.single .container-itens .figure-caption{
    z-index: 1;
    color: #fff !important;
    position: absolute;
    text-decoration: none !important;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(100%);
    padding: 10px;
    transition: all 0.5s;
}

.dropzone.single .container-itens .figure-caption *{
    color: inherit;
    text-decoration: inherit;
}

.dropzone.single .container-itens:hover .figure-caption{
    transform: none;
    transition: all 0.5s;
}


.dropzone.single .img-dropzone{
    max-height: 100%;
    max-width: 100%;
}



/*BUTTON*/
.dropzone-list .img-container .btn-del{
    padding: 10px;
    border-radius: 100%;
    position: absolute;
    top: 0px;
    right: 0px;
    color: #fff;
    transform: translateY(-150%);
}

.dropzone-list .img-container .btn-link{
    text-transform: none !important;
    text-decoration: none !important;
    color: inherit !important;
}

.dropzone-list .img-container:hover .btn-del{
    transform: none;
    transition: all 0.5s;
}

/*PROCESSO DE UPLOAD*/
.dropzone-progress{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
}

.dropzone-progress .load-progress{
    height: 5px;
    background: var(--color-primary);
}

