.page-login{
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #237df3, rgba(35,125,243,0.3));
}

.page-login > .card{
    max-width: 430px;
    border-radius: 50px 0;
}