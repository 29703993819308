.content-body {
    width: var(--width-content-body);
    padding: 15px;
    box-sizing: border-box;
    /* overflow-y: auto; */
    transition: all 0.2s;
    position: relative;
    background: #f8f9fe;
}


#content-page:not(.active)>.content-body {
    width: var(--width-content-body-sm);
    transition: all 0.2s;
}

@media (max-width: 992px) {
    #content-page:not(.active)>.content-body {
        width: 100%;
    }
}

@media (max-width:992px) {

    .content-body {
        width: 100%;
    }
}