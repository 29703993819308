.dropzone{
    position: relative;
    border-radius: 0.25rem;
    overflow: hidden;
}
.dropzone_input_file {
    display: block !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.dropzone-list a{
    color: inherit !important;
    text-decoration: none !important;
}

.dropzone-list .img-container{
    position: relative;
    overflow: hidden;
    padding: 10px;
    height: 100%;
    min-height: 110px;
    min-width: 200px;
    max-width: 100%;
}

.dropzone-list .img-container::before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.7);
    transform: translateY(100%);
}
.dropzone-list .img-container .figure-caption{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    transform: translateY(100%);
    color: #fff;
    padding: 5px;
    box-sizing: border-box;
}

.dropzone-list .img-container:hover .figure-caption{
    transform: none;
    transition: all 0.5s;
}
.dropzone-list .img-container:hover::before{
    transform: none;
    transition: all 0.5s;
}

.dropzone-list .img-container .img-dropzone{
    max-height: 100px;
}


/*BUTTON*/
.dropzone-list .img-container .btn-del{
    padding: 10px;
    border-radius: 100%;
    position: absolute;
    top: 0px;
    right: 0px;
    color: #fff;
    transform: translateY(-150%);
}

.dropzone-list .img-container .btn-link{
    text-transform: none !important;
    text-decoration: none !important;
    color: inherit !important;
}

.dropzone-list .img-container .btn-edit{
    padding: 10px;
    border-radius: 100%;
    position: absolute;
    top: 0px;
    right: 50px;
    color: #fff;
    transform: translateY(-150%);
}

.dropzone-list .img-container:hover .btn-edit,
.dropzone-list .img-container:hover .btn-del{
    transform: none;
    transition: all 0.5s;
}

/*PROCESSO DE UPLOAD*/
.dropzone-progress{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
}

.dropzone-progress .load-progress{
    height: 5px;
    background: var(--color-primary);
}

