.nav-bar {
    width: var(--width-nav-bar);
    height: 100%;
    overflow-y: auto;
    border-right: solid 2px #f4f4f4;
    z-index: 1;
    transition: all 0.2s;
}


#content-page:not(.active) .nav-bar {
    overflow-x: hidden;
    width: var(--width-nav-bar-sm);
    transition: all 0.2s;
}


@media (max-width: 992px) {
    #content-page:not(.active) .nav-bar {
        width: 0;
    }
}


.navbar-group-title {
    font-weight: 700;
    color: var(--color-title);
    user-select: none;
    font-size: 1.2rem;
    padding: 10px 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.2;
}

#content-page:not(.active) .navbar-group-title {
    display: none;
    transition: all 0.2;
}

.navbargroup-boby {
    padding-bottom: 15px;
    padding-top: 15px;
}

.navbar-group .nav-link {
    color: var(--color-link);
    font-weight: 400;
    padding-left: 15px;
    padding-right: 15px;
    background: transparent;
    transition: all 0.5s;
}

.navbar-group .nav-link:hover {
    background: whitesmoke;
}


.nav-container-link {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-start;
}

.navbar-group .nav-icon {
    font-size: 30px;
    padding-right: 15px;
    color: var(--color-icon);
}

.navbar-group .nav-link .title {
    font-size: 1rem;
    color: var(--color-link);
}





@media (max-width: 992px) {

    .nav-bar {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1020;
        background: #fff;
    }
}