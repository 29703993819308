.footer-page{
    width: 100%;
    z-index: 1040;
    min-height: var(--height-footer);
    height: var(--height-footer);
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
}

@media (max-width: 992px) {
    .footer-page{
        min-height: auto;
        height: auto;
        padding-top: 5px;
        padding-bottom: 5px;
    }
}