
.editor-text .public-DraftEditor-content{
    min-height: 350px;
}

.jodit-status-bar{
    display: none !important;
}
.jodit-add-new-line{
    display: none !important;
}