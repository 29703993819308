.filiado-header{
    font-size: 20px;
}
.filiado-header i{
    font-size: 35px;
}

.filiado-body{
    font-size: 16px;
}
.filiado-body i{
    font-size: 24px;
}
.filiado-body .icon{
    display: flex;
    align-items: center;
}