.App {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}

@media (max-width: 992px) {
    .App {
        /* height: calc(100vh - 75px); */
        height: calc(100vh);
    }
}

.content-page {
    height: calc(100% - var(--height-footer) - var(--height-header));
    width: 100%;
    position: relative;
}

@media (max-width: 992px) {
    .content-page {
        height: calc(100% - var(--height-header));
    }
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


.sweet-loading {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    z-index: 1090;
}

.loading-page {
    opacity: 0.6;
    pointer-events: none;
    overflow: hidden;
}

#content-body>.page {
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 15px;
}

@media (max-width: 992px) {
    #content-body>.page {
        padding-right: 5px;
    }
}

#badge-off-line {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: 0;
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;
    transition: all 0.5s;
}

#badge-off-line.show {
    max-width: 100%;
    transition: all 0.5s;
}

#pwa-notification {
    position: fixed;
    bottom: 60px;
    right: 40px;
    top: auto;
    left: auto;
    z-index: 1040;
    transition: all 0.5s;
}

#pwa-notification:hover {
    transform: scale(1.1);
    transition: all 0.5s;
}

@media (max-width:992px) {
    #pwa-notification {
        bottom: 20px;
        right: 20px;
        white-space: inherit !important;
    }
}


input::-webkit-calendar-picker-indicator {
    display: none;
}

input[type="date"]::-webkit-input-placeholder {
    visibility: hidden !important;
}