:root {
    --height-header: 70px;
    --height-footer: 50px;
    --width-nav-bar: 220px;
    --width-nav-bar-sm: 60px;
    --width-content-body: calc(100% - var(--width-nav-bar));
    --width-content-body-sm: calc(100% - var(--width-nav-bar-sm));
    --color-title: #9c9c9c;
    --color-link: rgba(0, 0, 0, 0.87);
    --color-icon: rgba(0, 0, 0, 0.54);
    --editar: var(--btn-dark);
    --card: var(--btn-info);
    --plano: var(--btn-info);
    --lancamento: var(--btn-success);
    --dependente: var(--btn-danger);
    --anexo: var(--btn-warning);
    --anotacao: var(--btn-warning);
    --terminal: var(--btn-secondary);
    --configuracao: var(--btn-primary);
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.55), rgba(255, 255, 255, 0));
}

@media (max-width:992px) {
    body #root div {
        white-space: break-spaces;
    }
}

#root .bg-active {
    background: #e8f3e8;
}

#root .bg-inactive {
    background: #e7cbcb;
}

body {
    margin: 0;
    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: rgba(0, 0, 0, 0.87);
    font-size: 13px;
    line-height: 1.5;
    letter-spacing: normal;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.avatar {
    clip-path: circle();
}

button {
    outline: none !important;
    background-color: inherit;
    border: inherit;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none !important;
    appearance: none !important;
}

/*Barra de scroll*/
*::-webkit-scrollbar-track,
::-webkit-scrollbar-track {
    background-color: #F4F4F4;
    scrollbar-base-color: #C0C0C0;
    scrollbar-base-color: #C0C0C0;
    scrollbar-3dlight-color: #C0C0C0;
    scrollbar-highlight-color: #C0C0C0;
    scrollbar-track-color: #EBEBEB;
    scrollbar-arrow-color: black;
    scrollbar-shadow-color: #C0C0C0;
}

*::-webkit-scrollbar,
::-webkit-scrollbar {
    width: 6px;
    background: #F4F4F4;
    scrollbar-base-color: #C0C0C0;
    scrollbar-base-color: #C0C0C0;
    scrollbar-3dlight-color: #C0C0C0;
    scrollbar-highlight-color: #C0C0C0;
    scrollbar-track-color: #EBEBEB;
    scrollbar-arrow-color: black;
    scrollbar-shadow-color: #C0C0C0;
}

*::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
    background: #dad7d7;
    scrollbar-base-color: #C0C0C0;
    scrollbar-base-color: #C0C0C0;
    scrollbar-3dlight-color: #C0C0C0;
    scrollbar-highlight-color: #C0C0C0;
    scrollbar-track-color: #EBEBEB;
    scrollbar-arrow-color: black;
    scrollbar-shadow-color: #C0C0C0;
}

::-webkit-scrollbar-thumb:hover,
*::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:active,
*::-webkit-scrollbar-thumb:active,
::-webkit-scrollbar-thumb:focus,
*::-webkit-scrollbar-thumb:focus {
    min-width: 15px !important;
}

::-webkit-scrollbar-corner,
*::-webkit-scrollbar-corner {
    background: #dad7d7;
    scrollbar-base-color: #C0C0C0;
    scrollbar-base-color: #C0C0C0;
    scrollbar-3dlight-color: #C0C0C0;
    scrollbar-highlight-color: #C0C0C0;
    scrollbar-track-color: #EBEBEB;
    scrollbar-arrow-color: black;
    scrollbar-shadow-color: #C0C0C0;
}

:root {
    scrollbar-color: #F4F4F4 !important;
    scrollbar-width: 2px !important;
    scrollbar-base-color: #C0C0C0;
    scrollbar-3dlight-color: #C0C0C0;
    scrollbar-highlight-color: #C0C0C0;
    scrollbar-track-color: #EBEBEB;
    scrollbar-arrow-color: black;
    scrollbar-shadow-color: #C0C0C0;
}

a {
    color: rgba(0, 0, 0, 0.87);
    transition: all 0.5s;
}

#root .content-body .btn {
    box-shadow: none !important;
}


#root .btn-lg {
    padding: 14px 1rem;
    font-size: 20px;
    line-height: 1.5;
    border-radius: 0.3rem;
}


#root .bg-primary,
#root .btn-primary,
#root .badge-primary,
#root .btn-primary:hover {
    background-color: var(--btn-primary) !important;
    border-color: var(--btn-primary) !important;
}

#root .bg-secondary,
#root .btn-secondary,
#root .badge-secondary,
#root .btn-secondary:hover {
    background-color: var(--btn-secondary) !important;
    border-color: var(--btn-secondary) !important;
}

#root .bg-success,
#root .btn-success,
#root .badge-success,
#root .btn-success:hover {
    background-color: var(--btn-success) !important;
    border-color: var(--btn-success) !important;
}

#root .bg-danger,
#root .btn-danger,
#root .badge-danger,
#root .btn-danger:hover {
    background-color: var(--btn-danger) !important;
    border-color: var(--btn-danger) !important;
}

#root .bg-warning,
#root .btn-warning,
#root .badge-warning,
#root .btn-warning:hover {
    background-color: var(--btn-warning) !important;
    border-color: var(--btn-warning) !important;
}

#root .btn-info,
#root .bg-info,
#root .badge-info,
#root .btn-info:hover {
    background-color: var(--btn-info) !important;
    border-color: var(--btn-info) !important;
}

#root .btn-light,
#root .bg-light,
#root .badge-light,
#root .btn-light:hover {
    background-color: var(--btn-light) !important;
    border-color: var(--btn-light) !important;
}

#root .bg-dark,
#root .btn-dark,
#root .badge-dark,
#root .btn-dark:hover {
    background-color: var(--btn-dark) !important;
    border-color: var(--btn-dark) !important;
}

#root .bg-link,
#root .btn-link,
#root .badge-link,
#root .btn-link:hover {
    background-color: var(--btn-link) !important;
    border-color: var(--btn-link) !important;
    color: var(--btn-primary);
}

#root .bg-gradient {
    background-image: var(--bs-gradient) !important;
}

#root .btn {
    appearance: none !important;
    -webkit-appearance: none !important;
    transition: all 0.5s;
}

#root .btn:hover {
    opacity: 0.8;
    transition: all 0.5s;
}

#root button * {
    pointer-events: none;
}

#root .custom-file-label::after {
    content: 'Anexo';
}

#root .shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

#root .card {
    margin-bottom: 30px;
    border: 0;
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
}

#root .modal-lg {
    max-width: 1100px;
}

#root form .row {
    margin-left: -7px;
    margin-right: -7px;
}

#root form .row>[class*="col"] {
    padding-left: 7px;
    padding-right: 7px;
}

.table-borderless td {
    border-top: 0 !important;
}

/* data-title */
[data-title] {
    position: relative;
    z-index: 1;
}

[data-title]::before {
    content: attr(data-title);
    position: absolute;
    right: 50%;
    top: 0px;
    transform: translate(50%, -100%);
    background: rgba(0, 0, 0, 0.9);
    /* min-width: 100%; */
    border-radius: 10px;
    color: #fff;
    white-space: initial;
    word-wrap: break-word;
    z-index: 1020;
    font-size: 10px;
    padding: 5px 10px;
    opacity: 0;
    pointer-events: none;
    transition: all 0.1s;
}

[data-title]:hover::before {
    opacity: 1;
    pointer-events: all;
    transition: all 0.2s;
}

body.loading {
    position: relative;
    height: 100%;
    min-height: 100vh;
}

body.loading::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1099;
    background-color: transparent;
    pointer-events: none;
}

.border.b-dashed {
    border-style: dashed !important;
    padding: 1px;
    margin-bottom: 2px;
}

.border.b-dashed.ctt {
    position: relative;
}

.border.b-dashed.ctt>.cmp-modal>.btn {
    float: right;
}

/* body.loading::after{
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 50%;
    height: 5px;
    border-radius: 15px 15px;
    z-index: 1090;
    background-color: #00427b;
    pointer-events: none;
} */


.modal .dropzone.single .dropzone-container {
    max-width: 100% !important;
    width: 100% !important;
}

.pg-cartao .dropzone.single .dropzone-container {
    max-width: 100% !important;
    width: 100% !important;
}



.bloco.bloco-slide,
.bloco.bloco-container,
.bloco.bloco-card {
    border: dashed 1px transparent;
    border-radius: 5px;
    box-sizing: border-box;
    padding-top: 5px;
    padding-bottom: 5px;
    transition: all 0.5s;
}

.bloco.bloco-slide:hover,
.bloco.bloco-container:hover,
.bloco.bloco-card:hover {
    border-color: var(--primary);
    transition: all 0.5s;
}

.dropzone-container .img-container {
    background-color: #f5f5f5;
    width: 100%;
}

.icon-manifest h4 {
    font-size: 16px;
}

.logo-manifest-share .dropzone.single .dropzone-container {
    width: 100%;
    max-width: 100%;
    max-height: 300px;
}

.icon-manifest .dropzone.single .dropzone-container {
    max-width: 120px;
    max-height: 120px;
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('../public/fonts/Lato-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../public/fonts/Lato-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../public/fonts/Lato-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../public/fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url('../public/fonts/Lato-Thin.ttf') format('truetype');
}