.header.header-nav {
    position: relative;
    width: 100%;
    min-height: var(--height-header);
    background: var(--color-primary);
    box-shadow: 0 0 7px 5px rgba(0, 0, 0, 0.2);
}

.header.header-nav nav {
    min-height: var(--height-header);
}

.header .btn-icon {
    background-color: transparent;
    padding: 0;
    font-size: 35px;
    color: #fff;
    outline: none;
    box-shadow: none !important;
}

.user-accont-container {
    position: relative;
}

#dropdown-user-accont {
    right: 0;
    left: auto;
    top: auto;
    bottom: -10px;
    transform: translateY(100%);
    width: 300px;
    z-index: 1080;
}

#user-accont::after {
    display: none;
}

@media (max-width: 992px) {
    .header.header-nav .nav-controler {
        max-width: 60%;
    }

    .header.header-nav .nav-controler .nav-title {
        max-width: 90%;
    }

    .header.header-nav .nav-controler .nav-title h4 {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
    }

    .header.header-nav .nav-user .row {
        margin-left: -7px;
        margin-right: -7px;
    }
    .header.header-nav .nav-user .row > div {
        padding-left: 7px;
        padding-right: 7px;
    }
}
